import React from 'react'
import PresetSentenceCreatePage from '../../../components/pages/StarTutoring/PresetSentenceCreatePage'
import StarTutoringLayout from '../../../components/pages/StarTutoring/StarTutoringLayout'

const Page = (props: any) => {
  return <StarTutoringLayout path={props.path}>
    <PresetSentenceCreatePage />
  </StarTutoringLayout>
}

export default Page
